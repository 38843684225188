<template>
  <div
    id="app"
    :class="{ '-extra-padding-top': showHeader && topNotificationOpen }"
  >
    <Navbar v-if="showHeader"></Navbar>
    <router-view />
    <AutoLogoutModal
      :visible="$autologout.countdown"
      :remaining="$autologout.duration"
      @hide="$autologout.reset()"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
import { EVENTS } from "@/types/Event";
import Navbar from "@/components/Navbar.vue";
import AutoLogoutModal from "@/components/modals/AutoLogout.vue";
import {
  userStore,
  subscriptionStore,
  notificationStore,
  featureStore,
  eventStore,
} from "./store";

@Component({
  components: {
    AutoLogoutModal,
    Navbar,
  },
  metaInfo() {
    const info: MetaInfo = {};

    if (this.$route.meta?.robotsIndexValue) {
      info.meta = [
        { name: "robots", content: this.$route.meta.robotsIndexValue },
      ];
    }

    info.title = this.$route.meta?.title
      ? `Privacy - ${this.$route.meta.title}`
      : "Privacy";

    return info;
  },
})
export default class App extends Vue {
  get currentPage(): string {
    return this.$route.path;
  }

  get showHeader() {
    return this.$route.matched.some((route) => route.meta.showHeader);
  }

  get topNotificationOpen() {
    return (
      notificationStore.getters.activeNotification &&
      !notificationStore.getters.hidden
    );
  }

  get isOnSharePage() {
    return this.currentPage.startsWith("/shared");
  }

  get isLoggedIn() {
    return userStore.getters.isLoggedIn && !this.isOnSharePage;
  }

  get user() {
    return userStore.getters.currentUser;
  }

  get liveChat() {
    return (
      subscriptionStore.getters.subscription?.features.supportLiveChat &&
      userStore.getters.currentUser?.canAccessLiveChat
    );
  }

  get shouldLogout() {
    return this.$autologout.end && !this.isOnSharePage;
  }

  @Watch("isLoggedIn", { immediate: true })
  handleLoggedInState() {
    if (this.isLoggedIn) {
      this.$autologout.start();
      this.$zendesk.identify({
        name: `${this.user!.preferredName} ${this.user!.lastName}`,
        email: this.user!.email,
      });
      this.$piwik.setUserId(this.user!._id);

      return;
    }

    if (!this.isOnSharePage) {
      this.$autologout.stop();
    }

    this.$zendesk.logout();
    this.$piwik.resetUserId();
  }

  @Watch("liveChat", { immediate: true })
  handleFeaturesChange() {
    if (this.liveChat) {
      this.$zendesk.updateSettings({
        webWidget: {
          chat: {
            suppress: false,
          },
        },
      });
    } else {
      this.$zendesk.updateSettings({
        webWidget: {
          chat: {
            suppress: true,
          },
        },
      });
    }
  }

  @Watch("shouldLogout", { immediate: true })
  handleLogout() {
    if (this.shouldLogout) {
      eventStore.actions.record({
        name: EVENTS.AUTH.LOGOUT,
        data: { source: "Auto Logout" },
      });

      this.$router.push("/logout");
    }
  }

  async created() {
    if (Vue.$cookies.get("token")) {
      try {
        await userStore.actions.getCurrentUser();
        await Promise.all([
          subscriptionStore.actions.fetchPlans(),
          subscriptionStore.actions.fetchSubscription(),
          featureStore.actions.fetchAll(),
        ]);
      } catch (e) {
        /* noop */
      }
    }
  }
}
</script>
<style lang="scss">
@import "./assets/styles/index.scss";

#app {
  height: 100%;

  &.-extra-padding-top {
    padding-top: 60px;
  }

  @media #{$media-phone} {
    &.-extra-padding-top {
      padding-top: 0;
    }
  }
}

body.webview [id^="ppms_cm_popup"] {
  display: none !important;
}
</style>
