export enum SubscriptionType {
  API_ISSUING = "API_ISSUING",
  CONSUMER_PLUS = "CONSUMER_PLUS",
  EMPLOYEE = "EMPLOYEE",
  FREE = "FREE",
  PROSUMER = "PROSUMER",
  SMB_ENTERPRISE = "SMB_ENTERPRISE",
  SMB_TEAM = "SMB_TEAM",
  STARTER_ISSUING = "STARTER_ISSUING",
}

export interface Plan {
  amount: number;
  description: {
    headline?: string;
    disclaimer?: string[];
    features: string[];
  };
  freeMonths: number;
  name: string;
  organization: boolean;
  type: SubscriptionType;
  uuid: string;
  cardTypes: {
    digitalWalletCards: boolean;
    categoryCards: boolean;
  };
}

export enum SubscriptionFundingType {
  DEFAULT = "DEFAULT",
  CARD = "CARD",
}

export interface PlanData extends Plan {
  displayType: string;
  iconSvg: string;
}

export interface PlanLimits {
  cardsCreatedLast30: number;
  cardsPerMonth: number;
}

export enum SubscriptionState {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  DELETED = "DELETED",
  DELINQUENT = "DELINQUENT",
  PENDING = "PENDING",
}

export interface SubscriptionConfig {
  cardCreateCost?: number;
  cardNotes?: boolean;
  cardSharing?: boolean;
  cardSharingSpendLimit: number;
  categoryCards?: boolean;
  digitalWalletCards?: boolean;
  digitalWalletCardsCountLimit?: number;
  displayName?: string;
  freeMonths?: number;
  internationalPerTransactionFee?: number;
  internationalTransactionFeeExempt?: boolean;
  internationalVolumeBpsFee?: number;
  monthlyCardCountLimit?: number;
  monthlyCardCreatesIncluded?: number;
  organization?: boolean;
  physicalCardCreateCost?: number;
  promoName?: string;
  revenueTierOneBps?: number;
  revenueTierOneVolumeThreshold?: number;
  revenueTierTwoBps?: number;
  supportLiveChat?: boolean;
  userCountLimit?: number;
}

export interface Subscription {
  billingAmount: number;
  cardNotes?: boolean;
  cardsCreatedLast30: number;
  cardsPerMonth: number;
  delinquentAmount?: number;
  displayName?: string;
  endDate?: string; // Date
  features: SubscriptionConfig;
  freeMonths: number;
  fundingBrand?: string;
  fundingExpiry?: string; // Date
  fundingLastFour?: string;
  fundingType?: SubscriptionFundingType;
  fundingUuid?: string;
  inPromoPeriod?: boolean;
  issuingPlan?: boolean;
  memo: string;
  nextChargeDate?: string; // Date
  pendingFailure?: boolean;
  state: SubscriptionState;
  subscriptionCreated?: string; // Date
  subscriptionType: string;
  subscriptionTypeID: number;
  subscriptionUuid: string;
}

export class SubscriptionStoreState {
  plans: Plan[] = [];
  limits?: PlanLimits;
  subscription?: Subscription;
}

export enum SubscriptionTypeID {
  FREE = -2,
}
