<template>
  <div class="kyb-verification-pages">
    <div class="kyb-verification-card">
      <ConsumerChargeDisclosure
        @complete-step="nextStep"
        :existingUser="existingUser"
        :isKybUser="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { userStore } from "@/store";
import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { Toast } from "@/mixins/Toast";
import ConsumerChargeDisclosure from "@/views/funding/ConsumerChargeDisclosure.vue";

@Component({
  components: {
    BAlert,
    SVGIcon,
    ConsumerChargeDisclosure,
  },
})
export default class ConsumerChargeDisclosureContainer extends Mixins(Toast) {
  nextStep() {
    this.$router.push({ name: "kybSuccess" });
  }

  get user() {
    return userStore.getters.currentUser;
  }

  get existingUser() {
    return (
      this.user?.hasHadAnyFundingSource &&
      !this.user?.accountPurpose &&
      !this.user?.chargeTermsAcceptTime &&
      !this.user?.hasManualPayments
    );
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: "Verification Consumer Charge Terms",
    });
    userStore.actions.updateVerificationStep("kybConsumerChargeDisclosure");
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  padding: 0;
  width: 580px;
}

.kyb-verification-card {
  width: auto;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

::v-deep .simple-page {
  padding: 0;
}
</style>
