enum PAGE_EVENTS {
  PAGEVIEW = "pageview",
}

enum SETTINGS_EVENTS {
  SET_ACCOUNT_PHOTO = "Settings: Set Account Photo",
}

enum FUNDING_SOURCE_EVENTS {
  ERRORED = "Funding Source: Add Errored",
  BLOCKED = "Funding Source: Add Blocked",
}

enum TRANSACTION_EVENTS {
  VIEWED = "Transaction: Viewed",
}

enum EXTENSION_EVENTS {
  INSTALLED = "Extension: Installed",
}

enum CTA_EVENTS {
  CLICKED = "CTA: Clicked",
  VAULTED_UPGRADE_CLICKED = "Vaulted Card Modal: Upgrade Clicked",
}

enum MODAL_EVENTS {
  VIEWED = "Modal: Viewed",
  VAULTED_CARD_SKIP = "Vaulted Card: Skip",
  VAULTED_CARD_SHOW = "Vaulted Card: Show",
}

enum AUTH_EVENTS {
  LOGIN = "Auth: Login",
  LOGOUT = "Auth: Logout",
}

enum CARD_EVENTS {
  VIEWED = "Card: Viewed",
  SET_NICKNAME = "Card: Set Nickname",
  SET_NOTES = "Card: Set Notes",
  SET_SPEND_LIMIT = "Card: Set Spend Limit",
  SET_STYLE = "Card: Set Style",
  SET_FUNDING_SOURCE = "Card: Set Funding Source",
  VIEWED_NUMBER = "Card: Viewed Number",
  COPIED_NUMBER = "Card: Copied Number",
  TAGGED = "Card: Tagged",
  UNTAGGED = "Card: Untagged",
  UPDATED_BILLING = "Card: Updated Billing",
  CREATE_BLOCKED = "Card: Create Blocked",
  SELECT_VAULTED_CARD = "Vaulted Card: Select",
  DESELECT_VAULTED_CARD = "Vaulted Card: Deselect",
  CREATED_VAULTED_CARDS = "Vaulted Card: Create",
}

enum EXPERIMENT_EVENTS {}

enum SUBSCRIPTION_EVENTS {
  BILLING_VIEWED = "Subscription Plan: Viewed",
}

export type EVENT_NAMES =
  | PAGE_EVENTS
  | SETTINGS_EVENTS
  | FUNDING_SOURCE_EVENTS
  | TRANSACTION_EVENTS
  | EXTENSION_EVENTS
  | CTA_EVENTS
  | MODAL_EVENTS
  | CARD_EVENTS
  | EXPERIMENT_EVENTS
  | AUTH_EVENTS
  | SUBSCRIPTION_EVENTS;

export interface Event {
  name: EVENT_NAMES;
  data?: {
    // Allow anything here - sorry typescript
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export interface PlaidEvent {
  eventName?: string;
  type?: string;
  err?: Error;
  metadata?: {
    // Allow anything here - sorry typescript
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export const EVENTS = {
  PAGE: PAGE_EVENTS,
  SETTINGS: SETTINGS_EVENTS,
  FUNDING_SOURCE: FUNDING_SOURCE_EVENTS,
  TRANSACTION: TRANSACTION_EVENTS,
  EXTENSION: EXTENSION_EVENTS,
  CTA: CTA_EVENTS,
  MODAL: MODAL_EVENTS,
  CARD: CARD_EVENTS,
  EXPERIMENT: EXPERIMENT_EVENTS,
  AUTH: AUTH_EVENTS,
  SUBSCRIPTION: SUBSCRIPTION_EVENTS,
};
