<template>
  <div class="web-push-provisioning d-flex flex-column">
    <div class="content">
      <img
        id="add-to-apple-wallet"
        src="@/assets/icons/add-to-apple-wallet.svg"
        alt="Add this virtual card to Apple Wallet"
        :class="{ 'wallet-badge': true, large }"
      />
      <div v-if="errorMsg">
        {{ errorMsg }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { loadScriptAsync } from "@/util";
import { Card } from "@/types/Card";
import { cardStore } from "@/store";

@Component({})
export default class WebPushProvisioning extends Vue {
  @Prop() cardProp?: Card;
  @Prop({ default: false }) large?: boolean;

  errorMsg = "";

  mounted() {
    loadScriptAsync(
      "initAddToAppleWallet",
      "https://smp-device-content.apple.com/navweb/asset/initAddToAppleWallet.js",
      this.onScriptLoadSuccess,
      this.onScriptLoadErrror
    );
  }

  async onScriptLoadSuccess() {
    // @ts-ignore initAddToAppleWallet.js script has already loaded successfully
    // and is available in the global scope
    initAddToAppleWallet({
      partnerId: "ORG-97a7c2b2-11ec-4d6d-a3f7-c3d06f4b2703",
      domain: "https://apple-pay.apple.com",
      buttonId: "add-to-apple-wallet",
      jwsResolver: this.jwsResolverCallback,
      resultResolver: this.resultResolverCallback,
    });
  }

  onScriptLoadErrror() {
    this.errorMsg = "Failed to load the Add to Apple Wallet script.";
  }

  jwsResolverCallback() {
    return cardStore.actions.pushProvisionCard({
      cardUuid: this.cardProp!.cardUuid,
      digitalWallet: "APPLE_PAY",
    });
  }

  resultResolverCallback(result: { status: string }) {
    const addToAppleWalletButton = document.getElementById(
      "add-to-apple-wallet"
    );
    if (result.status === "500") {
      this.errorMsg = "Failed to provision card.";
    } else if (result.status === "408") {
      this.errorMsg = "Failed to provision card: request timeout";
    } else if (
      ["200", "202", "206"].includes(result.status) &&
      addToAppleWalletButton
    ) {
      // In the case of success, hide the Add to Apple Wallet button
      addToAppleWalletButton.style.display = "none";
    }
  }
}
</script>

<style lang="scss" scoped>
.wallet-badge {
  cursor: pointer;
}

.wallet-badge.large {
  height: 59px;
}
</style>
