<template>
  <div class="banner-subscription-awareness" :class="media">
    <div class="wrapper">
      <div class="title">Want More Features? ✨</div>
      <div class="divider" />
      <div class="description">
        Enjoy access to premium features with plans starting at only $5/month.
      </div>
      <BaseButton
        class="button"
        @click="goToSubscriptionPlans"
        variant="primary"
      >
        Get Started
      </BaseButton>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BannerSubscriptionAwareness extends Vue {
  @Prop() media!: "mobile" | "desktop";
  goToSubscriptionPlans() {
    this.$router.push({ name: "subscription-plan" });
  }
}
</script>
<style lang="scss" scoped>
.mobile {
  display: none;

  @media #{$media-phone} {
    display: flex;
    padding: 24px;
  }
}

.desktop {
  display: flex-grow;
  margin: 0 auto;

  @media #{$media-phone} {
    display: none;
  }
}
.banner-subscription-awareness {
  width: 100%;
  padding-top: 40px;

  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, $blue 0%, $brand-purple 100%);
    border-radius: $border-radius-lg;
    padding: 16px 24px;
    color: $white;
    gap: 16px;

    @media #{$media-phone} {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
    }

    .title {
      font-weight: bold;
    }

    .divider {
      width: 1px;
      background-color: #f0f0f54d;
      height: 24px;

      @media #{$media-phone} {
        display: none;
      }
    }

    .button {
      background-color: $white;
      color: $neutrals-8;

      @media #{$media-phone} {
        margin-top: 10px;
      }
    }
  }
}
</style>
