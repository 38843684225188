<template>
  <div class="form-wrapper" ref="formElement" />
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

import { userStore } from "@/store";

@Component
export default class TypeFormWrapper extends Vue {
  @Prop({ default: "type-form-modal" }) modalId!: string;
  @Prop() formId!: string;

  mounted() {
    const user = userStore.getters.currentUser;
    const formElement = this.$refs.formElement as HTMLDivElement;
    if (formElement) {
      createWidget(this.formId, {
        container: formElement,
        hidden: {
          email: user?.email ?? "",
        },
        inlineOnMobile: true,
      });
    }
  }

  close() {
    this.$bvModal.hide(this.modalId);
  }
}
</script>

<style lang="scss" scoped>
.form-wrapper {
  min-height: 80vh;
}
</style>
