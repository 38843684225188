<template>
  <div class="progress-widget" data-test="progress-widget">
    <div class="upperContainer">
      <h2 class="title">Getting Started</h2>
      <button class="modal-close" @click="closeWidget">
        <b-icon icon="x" scale="2" />
      </button>
    </div>

    <div class="step-container">
      <div
        v-for="step in progressSteps"
        :key="'progress-step-' + step.id"
        :class="{
          active: step.id === currentStep,
          done: step.id < currentStep,
          'done-last': step.id === currentStep - 1,
        }"
        class="step-item"
      >
        <div class="step-icon">
          <SVGIcon
            v-if="step.id < currentStep"
            class="completed-icon"
            icon="circle-check"
            size="22"
          />
          <div v-if="step.id >= currentStep" class="pending-icon"></div>
        </div>
        <div class="step-title">{{ step.title }}</div>
        <div class="step-text" v-if="step.id === currentStep && step.text">
          {{ step.text }}
        </div>
      </div>
    </div>
    <div
      class="step-action"
      v-if="currentStepInfo && currentStepInfo.actionText"
    >
      <BaseButton
        @click="doAction"
        block
        variant="primary"
        data-test="start-onboarding"
      >
        {{ currentStepInfo && currentStepInfo.actionText }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BIcon, BIconX } from "bootstrap-vue";
import {
  OnboardingSteps,
  ProgressSteps,
  ProgressStepScreen,
} from "@/types/Onboarding";
import { transactionStore, userStore, featureStore } from "@/store/index";

import SVGIcon from "@/components/SVGIcon.vue";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { LDFlagValue } from "@launchdarkly/node-server-sdk";

@Component({
  components: {
    SVGIcon,
    BIcon,
    BIconX,
  },
})
export default class ProgressWidget extends Vue {
  skipOnboardingCardCreate = false;
  get progressSteps() {
    const defaultSteps = [
      {
        id: ProgressSteps.CREATE_ACCOUNT,
        title: "Create your account",
        actionText: "Create Account",
      },
      {
        id: ProgressSteps.CREATE_CARD,
        title: "Create your first Privacy virtual card",
        text: 'Create your first card by clicking below, or by clicking the "New Card" button to the left',
        actionText: "Create your first virtual card",
      },
      {
        id: ProgressSteps.COMPLETE_PROFILE,
        title: "Complete your profile",
        actionText: "Complete your profile",
      },
      {
        id: ProgressSteps.CONNECT_FUNDING,
        title: "Connect a funding source",
        actionText: "Connect a funding source",
      },
      {
        id: ProgressSteps.SIGN_TERMS,
        title: "Agree to our terms",
        actionText: "View Terms",
      },
      {
        id: ProgressSteps.DO_TRANSACTION,
        title: "Make your first purchase with Privacy",
      },
    ];
    if (!this.skipOnboardingCardCreate) {
      return defaultSteps;
    }
    return defaultSteps.filter((step) => step.id !== ProgressSteps.CREATE_CARD);
  }

  created(): void {
    // Both editedSampleCard and savedSampleCard were previously
    // mentioned in the code so a user might have either
    const oldSampleCard =
      this.$cookies.get("editedSampleCard") ||
      this.$cookies.get("savedSampleCard");
    if (oldSampleCard) {
      userStore.actions.updateSampleCard(oldSampleCard);
      this.$cookies.remove("editedSampleCard");
      this.$cookies.remove("savedSampleCard");
    }

    featureStore.actions
      .fetchFlag({ id: FeatureFlags.EXPERIMENT_ONBOARDING_SKIP_CARD })
      .then((value: LDFlagValue) => {
        this.skipOnboardingCardCreate = value;
      });
  }

  get currentStep(): ProgressSteps {
    const { currentUser } = userStore.getters;
    const onboardingStep = userStore.getters.nextOnboardingStep();
    const transactions = transactionStore.getters.getTransactions({});

    if (!currentUser?._id) {
      return ProgressSteps.CREATE_ACCOUNT;
    }

    if (
      onboardingStep <= OnboardingSteps.PURPOSE &&
      !currentUser?.sampleCard &&
      !this.skipOnboardingCardCreate
    ) {
      return ProgressSteps.CREATE_CARD;
    }

    if (onboardingStep <= OnboardingSteps.BUSINESS_USE) {
      return ProgressSteps.COMPLETE_PROFILE;
    }

    if (
      onboardingStep > OnboardingSteps.BUSINESS_USE &&
      onboardingStep <= OnboardingSteps.SUCCESS_BANK
    ) {
      return ProgressSteps.CONNECT_FUNDING;
    }

    if (
      onboardingStep > OnboardingSteps.SUCCESS_BANK &&
      onboardingStep <= OnboardingSteps.CHARGE_TERMS_SUCCESS
    ) {
      return ProgressSteps.SIGN_TERMS;
    }

    if (!transactions?.all?.length) {
      return ProgressSteps.DO_TRANSACTION;
    }

    return ProgressSteps.COMPLETE;
  }

  get currentStepInfo(): ProgressStepScreen | undefined {
    return this.progressSteps.find((step) => step.id === this.currentStep);
  }

  doAction(): void {
    this.$piwik.trackClick({ name: "Onboarding checklist CTA" });
    if (this.currentStep === ProgressSteps.CREATE_CARD) {
      this.$emit("create-sample-card");
    } else {
      this.$emit("start-onboarding");
    }
  }

  closeWidget(): void {
    this.$emit("hide-widget");
  }
}
</script>

<style lang="scss" scoped>
$step-progress-line-color: #d8dfe5;

.progress-widget {
  position: fixed;
  z-index: 99;
  right: 20px;
  bottom: 20px;
  padding: 40px;
  width: 400px;
  background-color: $white;
  box-shadow: $box-shadow-larger-faint, $box-shadow-hairline;
  border-radius: $radius-larger;

  @media #{$media-phone} {
    position: relative;
    width: 100%;
    border-radius: 0;
    right: 0;
    margin-top: -300px;
    margin-bottom: 40px;
    padding: 20px;
    box-shadow: $box-shadow-hairline;

    .step-container {
      overflow: scroll;
      height: 190px;
      padding-bottom: 36px;
      mask-image: linear-gradient(
        to bottom,
        black calc(100% - 48px),
        transparent 100%
      );
    }
  }
  .upperContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .modal-close {
      opacity: 0.5;
      background: none;
      border: none;

      &:hover {
        opacity: 1;
      }
    }
    .title {
      margin-bottom: 34px;
      font-family: $font-stack-wes-fy;
      font-size: 1.5rem;
      font-weight: 900;
      line-height: 1;
      color: $gray-900;

      @media #{$media-phone} {
        margin-bottom: 20px;
        font-size: 1.25rem;
      }
    }
  }

  .step-item {
    position: relative;
    padding-left: 40px;

    &:not(:last-child) {
      padding-bottom: 24px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        width: 2px;
        background-color: $step-progress-line-color;
        transform: translateY(2px);
      }
    }

    .step-title {
      font-weight: 700;
      font-family: $font-stack-lato;
      color: $gray-900;
    }

    &.done {
      .step-title {
        color: $color-green;
      }

      &:before {
        background-color: $color-green;
      }
    }

    &.done-last {
      &:before {
        background: $color-green;
        background: -moz-linear-gradient(
          top,
          $color-green 0%,
          $step-progress-line-color 100%
        );
        background: -webkit-linear-gradient(
          top,
          $color-green 0%,
          $step-progress-line-color 100%
        );
        background: linear-gradient(
          to bottom,
          $color-green 0%,
          $step-progress-line-color 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='$step-progress-line-color',GradientType=0 );
      }
    }
  }

  .step-icon {
    position: absolute;
    top: 0;
    left: 0;

    .completed-icon,
    .pending-icon {
      height: 22px;
      width: 22px;
    }

    .completed-icon {
      vertical-align: baseline;
    }

    .pending-icon {
      background-color: $white;
      border: 2px solid $step-progress-line-color;
      border-radius: 50%;
    }
  }

  .step-text {
    margin-top: 10px;
    font-family: $font-stack-lato;
    color: $gray-600;
  }

  .step-action {
    margin-top: 34px;
    font-family: $font-stack-lato;

    @media #{$media-phone} {
      margin-top: 0;
    }
  }
}
</style>
